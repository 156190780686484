import React, {Component} from "react"
import InViewMonitor from "react-inview-monitor"
import {withGlobalState} from 'react-globally'
import WorkItem from "./work-item"
import WorkGalleryLoader from "./work-gallery-loader"
import classnames from 'classnames'
import { cloneDeep } from 'lodash'
import axios from "axios"

class ListWorks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      activePopup: false,
      targetId: '',
      targetBgColor: '',
      targetPosTop: 0,
      activeGalleryPopup: false,
      galleryId: '',
    }
  }

  offset = (el) => {
      var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  loadData() {
    {/*fetch('/json/data-works.json').then(response => response.json()).then(({works: items}) => this.setState({items})) */}
    axios.get('/json/data-works.json')
    .then(response => response.data)
    .then(({works: items}) => this.setState({items}))
  }

  modalPop = (e) => {

    var elDisplayUpdate  =   this.props.elDisplayUpdate

    var targetEl = document.querySelector('[data-id="'+e.currentTarget.dataset.id+'"]')

    this.setState({
      targetId: e.currentTarget.dataset.id,
      targetBgColor: e.currentTarget.dataset.bg,
      galleryId: e.currentTarget.dataset.galleryId,
      targetPosTop: targetEl.offsetTop,
    })

    if (!this.state.activePopup) {
      this.setState({
        activePopup: true,
      })
      elDisplayUpdate(true)
    }

  }

  ClosePopup = (e) => {


    var elDisplayUpdate  =   this.props.elDisplayUpdate
    elDisplayUpdate(false)

    this.setState({
      activePopup: !this.state.activePopup,
    })


    return false
  }

  handleToUpdate = (galleryPopupStatus) => {
    this.setState({
      activeGalleryPopup: galleryPopupStatus,
    })
    return false
  }

  componentDidMount() {
    this.loadData()
  }

  render() {

    let items = this.state.items
    const itemBgColor = this.state.targetBgColor

    return (<> {
      items.map((item, i) =>
        <section
        className={
        'fn-mop-stage inview static' + (
          (item.projectID == this.state.targetId) && this.state.activePopup
          ? ' activated'
          : '') + (this.state.activeGalleryPopup ? ' blur' : '')}
          style={{ backgroundColor: "" + (
            (item.projectID == this.state.targetId) && this.state.activePopup
            ? this.state.targetBgColor
            : '') }} data-id={item.projectID} data-gallery-id={i} data-bg={'#'+item.bgColor} onClick={this.modalPop} key={item.projectID}>

        <button className="btn-back top" onClick={this.ClosePopup} ><i className="fa fa-angle-left ease" aria-hidden="true"></i> Back</button>
        <InViewMonitor classNameNotInView={'inview'} classNameInView={'inview on'} toggleClassNameOnInView={true}>
          <WorkItem itemData={item} handleToUpdate = {this.handleToUpdate}/>
        </InViewMonitor>
      </section>)
    }
    <WorkGalleryLoader displayStatus={this.state.activeGalleryPopup} handleToUpdate = {this.handleToUpdate}  galleryId={this.state.galleryId} />
    < />
    )
  }

}


export default withGlobalState(ListWorks)
