import React, {Component} from "react"
import {Link} from "gatsby"
import ListWorks from "./listWorks"
import { withGlobalState } from 'react-globally'
import LogoImg from "../../public/images/logo.gif"
import BtnGoContact from "../components/btnPageToContact"

class WorkLoader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
  }

  elDisplayUpdate = (elPopupStatus) => {
    this.setState({
      elDisplayUpdate: elPopupStatus,
    })
    return false
  }

  componentDidMount() {
    this.props.setGlobalState({
      popupStatus: false
    })
  }

  render() {
    
    return (<>
      <div className={"box-logo" + (this.state.elDisplayUpdate ? ' hidden':'')}>
        <div className="inner-wrap">
          <section>
            <Link to="/"><img src={LogoImg} className="logo ease" /></Link>
          </section>
        </div>
      </div>
      <BtnGoContact displayStatus={this.state.elDisplayUpdate} />
      <div className={"wrapper sect-works ease" + (this.props.globalState.popupStatus ? ' activated':'')}>
        <div className="container">
          <h2>WORKS</h2>
          <ListWorks elDisplayUpdate = {this.elDisplayUpdate}></ListWorks>
        </div>
      </div>
      < />
    )
  }
}


export default withGlobalState(WorkLoader)
