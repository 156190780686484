import React, {Component} from "react"
import { withGlobalState } from 'react-globally'
import axios from "axios"

class WorkGalleryLoader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
    }
  }

  loadData() {
    axios.get('/json/data-works.json')
    .then(response => response.data)
    .then(({works: items}) => this.setState({items}))
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    var handleToUpdate  =   this.props.handleToUpdate
    let currentGallery = this.state.items[this.props.galleryId]
    const imgList = []

    if (this.props.displayStatus) {

      for (var i = 0; i < currentGallery.screenLgth; i++) {
        imgList.push('<div class="slide-item"><span class="thumb"><img src="../data/screens/' + currentGallery.projectID + '/' + currentGallery.projectID + '-img-' + (i + 1) + '.jpg"/></span></div>');
      }

      return (<>
        <div className={"module-slideshow" + (this.props.displayStatus ? ' activated' : '')}>
      		<nav className="nav-panel-close">
      			<div className="box-close">
      				<button onClick={() => handleToUpdate(false)} className="btn-panel-close"><i className="fa fa-times" aria-hidden="true"></i></button>
      			</div>
      		</nav>
          <div className="thumb-slideshow" dangerouslySetInnerHTML={{__html: imgList.join("")}}></div>
      	</div>
        < />
      )
    }
    return (<></>)
  }
}


export default withGlobalState(WorkGalleryLoader)
