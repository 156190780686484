import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WorkLoader from "../components/work-loader"

const PageWorks = () => (<Layout>
  <SEO title="Works"/>
  <WorkLoader />
</Layout>)

export default PageWorks
