import React, {Component} from "react"
import {withGlobalState} from 'react-globally'
import ProjectTypeOpt from "./settings"

class WorkItem extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
  }

  render() {

    const item = this.props.itemData
    const typeOpt = ProjectTypeOpt.customProperties.variables.projectTypeOpt
    var handleToUpdate  =   this.props.handleToUpdate

    return (<>
      <span className="bar-top-mask hidden" style={{
          backgroundColor: '#' + item.bgColor
        }}></span>
      <div className={"mop-device " + item.displayType}><img src={item.displayThumb}/></div>
      <div className="col-desc">
        <h3>{item.title}</h3>
        <dl className="row">
          <dt className="col-sm-12 col-xs-12">
            <h4>Project</h4>
          </dt>
          <dd className="col-sm-12 col-xs-12" data-id="data-project-property">{item.project}</dd>
          <dt className="col-sm-12 col-xs-12">
            <h4>Type</h4>
          </dt>
          <dd className="col-sm-12 col-xs-12" data-id="data-project-property">{(typeOpt)[item.type-1]}</dd>
          <dt className="col-sm-12 col-xs-12">
            <h4>Tools</h4>
          </dt>
          <dd className="col-sm-12 col-xs-12" data-id="data-dev-tools">{item.tools}</dd>
          <dt className="col-sm-12 col-xs-12">
            <h4>Status</h4>
          </dt>
          <dd className="col-sm-12 col-xs-12" data-id="data-project-status">{item.status}</dd>
        </dl>
        <div className="bar-btm">
          <ul>
            {
              item.status!="Offline" && item.status!="Expired"?
              <li>
                <a href={item.url} className="btn-url btn-circle" target="_blank">
                  <i className="fa fa-link" aria-hidden="true"></i>
                </a>
              </li>:''
            }
            <li>
              <button onClick={() => handleToUpdate(true)} className="btn-gallery btn-circle" data-gallery-id={item.projectID} data-screen-lght={item.screenLgth}>
                <i className="fa fa-file-image-o" aria-hidden="true"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <span className="bg" style={{
          background: '#' + item.bgColor
        }}></span>
    < />
    )
  }
}


export default withGlobalState(WorkItem)
